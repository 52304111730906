// src/ProfileContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchProfiles,
  setSelectedProfile,
} from "./redux/slices/profilesDataSlice";
import axios from "axios";

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { profiles, selectedProfile, profilesLoading } = useSelector(
    (state) => state.profilesData
  );
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const initializeProfiles = async () => {
      await dispatch(fetchProfiles());
      setInitialized(true);
    };
    initializeProfiles();
  }, [dispatch]);

  useEffect(() => {
    if (initialized) {
      if (profiles.length > 0) {
        const storedProfileId = localStorage.getItem("selectedProfileId");
        if (storedProfileId && storedProfileId !== "null") {
          const profile = profiles.find((p) => p.id === storedProfileId);
          if (profile) {
            dispatch(setSelectedProfile(profile));
          } else {
            // If selected profile is not in profiles array
            dispatch(setSelectedProfile(profiles[0]));
          }
        } else {
          // additional no profile selected logic
          handleProfileSelect(profiles[0]);
        }
      } else {
        localStorage.setItem("selectedProfileId", "null");
      }
    }
  }, [profiles, initialized, dispatch]);

  const handleProfileSelect = (profile) => {
    dispatch(setSelectedProfile(profile));
    localStorage.setItem("selectedProfileId", profile.id);
  };

  const handleProfileSelectById = (id) => {
    const selectedProfile = profiles.find((profile) => profile.id === id);

    if (selectedProfile) {
      dispatch(setSelectedProfile(selectedProfile)); // Dispatch the found profile
      localStorage.setItem("selectedProfileId", id); // Save selected profile ID in localStorage
    } else {
      console.warn(`Profile with id ${id} not found.`);
    }
  };

  const addProfile = async (name) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/profile`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );
      const newProfileId = response.data;
      localStorage.setItem("selectedProfileId", newProfileId);
      await dispatch(fetchProfiles()); // Refresh profiles after adding a new one
    } catch (err) {
      console.error("Failed to add profile or update profiles.", err);
    }
  };

  return (
    <ProfileContext.Provider
      value={{
        selectedProfile,
        handleProfileSelect,
        handleProfileSelectById,
        profiles,
        addProfile,
        profilesLoading,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);
