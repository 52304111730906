import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Login from "./components/Login/Login";
import HomePage from "./components/HomePage/HomePage";
import AddProfile from "./components/HomePage/AddProfile/AddProfile";
import Profile from "./components/HomePage/Profile/Profile";
import Diet from "./components/HomePage/Diet/Diet";
import ProductList from "./components/HomePage/ProductList/ProductList";
import ProfileList from "./components/HomePage/ProfileList/ProfileList";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import RedirectIfLoggedIn from "./components/Redirects/RedirectIfLoggedIn";
import RedirectIfNoProfiles from "./components/Redirects/RedirectIfNoProfiles";
import { AuthProvider } from "./AuthContext";
import { ProfileProvider } from "./ProfileContext";
import NotFound from "./components/Errors/NotFound/NotFound"; // Import the 404 page
import Signup from "./components/Login/Signup";
import EmailConfirmation from "./components/Login/EmailConfirmation/EmailConfirmation";
import ForgotPassword from "./components/Login/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/Login/ForgotPassword/ResetPassword";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ProfileProvider>
          <div className="App">
            <Routes>
              <Route path="/signup" element={<Signup />} />
              <Route
                path="/login"
                element={
                  <RedirectIfLoggedIn>
                    <Login />
                  </RedirectIfLoggedIn>
                }
              />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/confirm" element={<EmailConfirmation />} />
              <Route
                element={
                  <ProtectedRoute>
                    <HomePage />
                  </ProtectedRoute>
                }
              >
                <Route
                  path="profile/:profileId?"
                  element={
                    <RedirectIfNoProfiles>
                      <Profile />
                    </RedirectIfNoProfiles>
                  }
                />
                <Route
                  path="diet"
                  element={
                    <RedirectIfNoProfiles>
                      <Diet />
                    </RedirectIfNoProfiles>
                  }
                />
                <Route
                  path="product-list"
                  element={
                    <RedirectIfNoProfiles>
                      <ProductList />
                    </RedirectIfNoProfiles>
                  }
                />
                <Route
                  path="profile-list"
                  element={
                    <RedirectIfNoProfiles>
                      <ProfileList />
                    </RedirectIfNoProfiles>
                  }
                />
                <Route path="add-profile" element={<AddProfile />} />
              </Route>
              <Route path="/" element={<Navigate to="/profile" />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </ProfileProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
