import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchProfiles } from "../../../redux/slices/profileListSlice";
import ProfileTable from "./ProfileTable/ProfileTable";
import SearchBar from "./SearchBar/SearchBar";
import styles from "./ProfileList.module.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const ProfileList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profiles, loading, error, totalPages } = useSelector(
    (state) => state.profileList
  );

  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const size = 14;

  useEffect(() => {
    dispatch(fetchProfiles({ page, size, term: searchTerm }));
  }, [page, size]);

  useEffect(() => {
    setSearchResults(profiles);
  }, [profiles]);

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleAddProfile = () => {
    navigate("/add-profile"); // Redirect to profile creation page
  };

  return (
    <div className={styles["profile-list-container"]}>
      <div className={styles["profile-search-row"]}>
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSearchResults={setSearchResults}
          profiles={profiles}
        />
        <button
          className={styles["add-profile-btn-wrapper"]}
          onClick={handleAddProfile}
        >
          <FontAwesomeIcon
            icon={faPlus}
            className={styles["add-profile-btn"]}
            size="3x"
          />
        </button>
      </div>

      <ProfileTable profiles={searchResults || []} />

      <div className={styles["pagination-controls"]}>
        <button disabled={page === 0} onClick={handlePrevPage}>
          {t("pagination.previous")}
        </button>
        <span>
          {t("pagination.page")} {page + 1 > totalPages ? totalPages : page + 1}{" "}
          {t("pagination.of")} {totalPages}
        </span>
        <button disabled={page >= totalPages - 1} onClick={handleNextPage}>
          {t("pagination.next")}
        </button>
      </div>
    </div>
  );
};

export default ProfileList;
