import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchProfiles = createAsyncThunk(
  "profileList/fetchProfiles",
  async ({ page = 0, size = 15, term = "" }, { rejectWithValue }) => {
    try {
      // Fetch profile list with search and pagination
      const response = await axios.get(`${API_URL}/profile/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          "ngrok-skip-browser-warning": "true",
        },
        params: {
          page,
          size,
          term,
        },
      });

      const { content, totalPages, number } = response.data;

      return {
        profiles: content,
        totalPages,
        currentPage: number,
      };
    } catch (error) {
      return rejectWithValue("Error fetching profiles");
    }
  }
);

const profileListSlice = createSlice({
  name: "profileList",
  initialState: {
    profiles: [],
    loading: false,
    error: null,
    totalPages: 0,
    currentPage: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProfiles.fulfilled, (state, action) => {
        state.loading = false;
        state.profiles = action.payload.profiles;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchProfiles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default profileListSlice.reducer;