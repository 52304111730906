import React from "react";
import styles from "../../ProductList/ProductList.module.css";
import searchBarStyles from "../../ProductList/SearchBar/SearchBar.module.css";
import { useTranslation } from "react-i18next";
import { useProfile } from "../../../../ProfileContext";

const ProfileTable = ({ profiles }) => {
  const { t } = useTranslation();
  const { handleProfileSelect } = useProfile();

  return (
    <div
      className={`${styles["product-list-table"]} table-bordered table-responsive`}
    >
      <div className={`${styles["thead-row"]} row`}>
        <div className={`${styles["table-col"]} col`}>
          {t("profileList.name")}
        </div>
        <div className={`${styles["table-col"]} col`}>
          {t("profileList.age")}
        </div>
        <div className={`${styles["table-col"]} col`}>
          {t("profileList.gender")}
        </div>
        <div className={`${styles["table-col"]} col`}>
          {t("profileList.height")}
        </div>
        <div className={`${styles["table-col"]} col`}>
          {t("profileList.weight")}
        </div>
        <div className={`${styles["table-col"]} col`}>
          {t("profileList.calories")}
        </div>
        <div className={`${styles["table-col"]} col`}>{}</div>
      </div>

      {profiles.map((profile) => (
        <div
          key={profile.id}
          className={`${styles["table-row"]} row align-items-center`}
        >
          <div
            className={`${styles["table-col"]} col text-truncate`}
            title={profile.name}
          >
            {profile.name}
          </div>
          <div className={`${styles["table-col"]} col`}>{profile.age}</div>
          <div className={`${styles["table-col"]} col`}>
            {profile.gender || "-"}
          </div>
          <div className={`${styles["table-col"]} col`}>{profile.height}</div>
          <div className={`${styles["table-col"]} col`}>{profile.weight}</div>
          <div className={`${styles["table-col"]} col`}>
            {profile.selectedCalorieLimit}
          </div>
          <div className={`${styles["table-col"]} col`}>
            {" "}
            <button
              className={`${searchBarStyles.search__button}`}
              onClick={() => {
                window.open(`/profile/${profile.id}`, "_blank");
              }}
            >
              {t("profileList.open")}
            </button>
          </div>{" "}
        </div>
      ))}
    </div>
  );
};

export default ProfileTable;
